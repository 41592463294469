export const NODE_ENV_DEV = process.env.NODE_ENV === `development`
export const NODE_ENV_PROD = process.env.NODE_ENV === `production`

export const GA_TRACKING_ID = process.env.GA_TRACKING_ID ?? ``
export const INITIAL_INVESTMENT: number = Number(
  process.env.NEXT_PUBLIC_INITIAL_INVESTMENT ?? '0'
)

export const COINMARKETCAP_API_KEY: string =
  process.env.COINMARKETCAP_API_KEY ?? ``

export const BCRYPT_HASH_ROUNDS = process.env.BCRYPT_HASH_ROUNDS ?? 10

export const SECRET_COOKIE_PASSWORD = process.env.SECRET_COOKIE_PASSWORD ?? ''

export const BINANCE_API_URL = process.env.BINANCE_API_URL ?? ''

export const IP_GEO_API_URL = process.env.IP_GEO_API_URL ?? ''

export const IP_GEO_API_KEY = process.env.IP_GEO_API_KEY ?? ''

export const SENTRY_DSN = process.env.SENTRY_DSN ?? ''
