import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition
} from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

type FAQ = {
  question: string
  answer: string
}

export const faqEN: FAQ[] = [
  {
    question: 'What is this platform used for?',
    answer:
      'This platform is designed to provide insights into your cryptocurrency portfolio by integrating with various exchange APIs. It allows you to view and analyze all your crypto assets in one place, offering a comprehensive overview of your investments. Please note that this platform does not facilitate trading actions; it only offers insights and analytics.'
  },
  {
    question: 'Do you offer trading signals or guaranteed returns?',
    answer:
      'No, we do not provide trading signals or guaranteed returns. Users are encouraged to conduct their own research (DYOR) before making any investment decisions. The platform is designed to help you make informed decisions based on real-time data, but it does not offer financial services, advice or guarantee any specific outcomes. Always exercise caution and make sure to thoroughly research any investment opportunities.'
  },
  {
    question:
      'Do I need to provide my API key and secret every time I use the platform?',
    answer:
      'Yes, for security reasons, you need to provide your API key and secret each time you log in to ensure secure access to your exchange accounts. This helps protect your account from unauthorized access and ensures that your data is secure. The platform does not store your API keys and secrets, so you can be confident that your sensitive information is safe.'
  },
  {
    question: 'What permissions should my API key have?',
    answer:
      "Your API key should have read-only permissions enabled to allow the platform to access your account data. This includes permissions for viewing your account balance and accessing your trading history. It is important to ensure that your API key has the necessary permissions to fully utilize the platform's features."
  },
  {
    question: 'Can I add more layers of security to my API keys?',
    answer:
      "Yes, you can enhance the security of your API keys by restricting their usage based on IP addresses. This means that only requests coming from specified IP addresses will be allowed to use the API keys. This adds an additional layer of security to protect your account from unauthorized access. Please refer to your exchange's documentation on how to set up IP whitelisting for your API keys."
  },
  {
    question: 'Which exchanges are currently supported?',
    answer:
      'Currently, the platform supports integration with Binance. We are working on adding support for other exchanges in the future to provide more options for our users. Stay tuned for updates on new exchange integrations.'
  }
]

export const faqPT = [
  {
    question: 'Para que serve esta plataforma?',
    answer:
      'Esta plataforma foi projetada para fornecer insights sobre seu portfólio de criptomoedas integrando-se a várias APIs de exchange. Ela permite que você visualize e analise todos os seus ativos de criptomoedas em um só lugar, oferecendo uma visão geral abrangente de seus investimentos. Observe que esta plataforma não facilita ações de negociação; ela oferece apenas insights e análises.'
  },
  {
    question: 'Vocês oferecem sinais de negociação ou retornos garantidos?',
    answer:
      'Não, não fornecemos sinais de negociação ou retornos garantidos. Os usuários são incentivados a realizar suas próprias pesquisas (DYOR) antes de tomar qualquer decisão de investimento. A plataforma foi projetada para ajudá-lo a tomar decisões informadas com base em dados em tempo real, mas não oferece aconselhamento financeiro ou garante resultados específicos. Sempre tenha cautela e certifique-se de pesquisar minuciosamente qualquer oportunidade de investimento.'
  },
  {
    question:
      'Preciso fornecer minha chave de API e segredo toda vez que usar a plataforma?',
    answer:
      'Sim, por motivos de segurança, você precisa fornecer sua chave de API e segredo toda vez que fizer login para garantir acesso seguro às suas contas em exchanges. Isso ajuda a proteger sua conta contra acesso não autorizado e garante que seus dados estejam seguros. A plataforma não armazena suas chaves de API e segredos, então você pode ter certeza de que suas informações confidenciais estão seguras.'
  },
  {
    question: 'Quais permissões minha chave de API deve ter?',
    answer:
      'Sua chave de API deve ter permissões de leitura habilitadas para permitir que a plataforma acesse os dados da sua conta. Isso inclui permissões para visualizar o saldo da sua conta e acessar seu histórico de negociação. É importante garantir que sua chave de API tenha as permissões necessárias para utilizar totalmente os recursos da plataforma.'
  },
  {
    question:
      'Posso adicionar mais camadas de segurança às minhas chaves de API?',
    answer:
      'Sim, você pode aumentar a segurança das suas chaves de API restringindo seu uso com base em endereços IP. Isso significa que apenas solicitações vindas de endereços IP especificados poderão usar as chaves de API. Isso adiciona uma camada adicional de segurança para proteger sua conta contra acesso não autorizado. Consulte a documentação da sua exchange sobre como configurar a lista de permissões de IP para suas chaves de API.'
  },
  {
    question: 'Quais exchanges são suportadas atualmente?',
    answer:
      'Atualmente, apenas a Binance é permitida. Estamos no processo de integração de outras exchanges para expandir nosso suporte. Isso permitirá que os usuários conectem e gerenciem suas carteiras em várias exchanges. Fique atento aos anúncios sobre novas integrações de exchanges.'
  }
]

export const FAQ = () => {
  return (
    <div
      id="faq"
      className="w-full max-w-[900px] mx-auto px-4 py-16  text-white"
    >
      <h1 className="text-neutral-300 text-center font-title font-bold max-md:text-3xl text-[64px] my-8 mb-16">
        FAQ
      </h1>
      <div className="space-y-4">
        {faqEN.map((faq, index) => (
          <Disclosure
            as="div"
            key={index}
            className="bg-platform border border-neutral-800 rounded-lg shadow-sm"
          >
            {({ open }) => (
              <>
                <DisclosureButton className="flex justify-between w-full px-4 py-4 text-lg font-medium text-left text-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75">
                  <span className="font-landing">{faq.question}</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-primary`}
                  />
                </DisclosureButton>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <DisclosurePanel className="px-4 pt-2 pb-4 text-gray-400 font-landing">
                    {faq.answer}
                  </DisclosurePanel>
                </Transition>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  )
}
