import { BillingProductModel } from '@/domain/models'

import { Color } from '../components/ui'

type Feature = {
  description: string
  available: boolean
}

type Plan = {
  index: number
  name: string
  features: Feature[]
  color: string
  buttonLabel: string
  buttonColor: Color
  active: boolean
}

const Beta: Plan[] = [
  {
    index: 0,
    name: 'Beta',
    features: [
      { description: 'One Exchange (read only)', available: true },
      { description: 'Real-Time Market Data', available: true },
      { description: 'Real-Time Portfolio Tracking', available: true },
      { description: 'Multi-Timeframe Insights', available: true },
      { description: 'Indicators-based Alerts', available: false },
      // { description: 'Automated Robots', available: false },
      { description: 'Risk-Management Insights', available: false },
      { description: 'Customizable Settings', available: false }
      // { description: 'Rebalancing Controls', available: true }
    ],
    color: 'hover:border-cyan-800 text-cyan-500',
    buttonLabel: 'Submit For Beta Program',
    buttonColor: 'light',
    active: true
  }
]

const Pro: Plan[] = [
  {
    index: 1,
    name: 'Pro',
    features: [
      { description: 'Multi-Exchange (read only)', available: true },
      { description: 'Real-Time Market Data', available: true },
      { description: 'Real-Time Portfolio Tracking', available: true },
      { description: 'Multi-Timeframe Insights', available: true },
      { description: '20 Indicators-based Alerts', available: true },
      // { description: 'Automated Robots', available: false },
      { description: 'Risk-Management Insights', available: true },
      { description: 'Customizable Settings', available: true }
      // { description: 'Rebalancing Controls', available: true }
    ],
    color: 'hover:border-yellow-800 text-primary',
    buttonLabel: 'Join the Waiting List',
    buttonColor: 'primary',
    active: false
  }
]

const Vip: Plan[] = [
  {
    index: 2,
    name: 'Enterprise',
    features: [
      { description: 'Multi-Exchange (read only)', available: true },
      { description: 'Real-Time Market Data', available: true },
      { description: 'Real-Time Portfolio Tracking', available: true },
      { description: 'Multi-Timeframe Insights', available: true },
      { description: 'Unlimited Indicators-based Alerts', available: true },
      // { description: 'Automated Robots', available: true },
      { description: 'Risk-Management Insights', available: true },
      { description: 'Customizable Settings', available: true }
      // { description: 'Rebalancing Controls', available: true }
    ],
    color: 'hover:border-violet-800 text-violet-500',
    buttonLabel: 'Join the Waiting List',
    buttonColor: 'violet',
    active: false
  }
]

export const pricingPlans = [...Beta, ...Pro, ...Vip]

export const usePricing = (pricing: BillingProductModel[]) => {
  const plans = pricing
    .map(plan => {
      return {
        ...plan,
        ...pricingPlans.find(p => plan.name.includes(p.name))!
      }
    })
    .sort((a, b) => a.index - b.index)

  return { plans }
}
