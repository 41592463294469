'use client'

/* eslint-disable @next/next/no-img-element */
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'

import { Button } from '@/components/ui/button'
import { BillingProductModel } from '@/domain/models'
import { useLocaleContext } from '@/presentation/contexts/locale'

import { FAQ } from './components/faq'
import { Footer } from './components/footer'
import { Pricing } from './components/pricing-cards'

type Props = {
  pricing: BillingProductModel[]
}

const LandingPageView: React.FC<Props> = ({ pricing }) => {
  const [loading, setLoading] = useState('')

  const router = useRouter()

  const {
    locale,
    localeContent: { hero },
    toggleLocale
  } = useLocaleContext()

  return (
    <div className="bg-black w-full">
      {hero && (
        <div className="flex justify-between gap-2 px-4 py-4">
          <div className="flex gap-2 cursor-pointer">
            <Image src="/logo.svg" alt="Logo" height={24} width={24} />
          </div>

          <div className="flex justify-start items-center content-start cursor-pointer">
            <Button
              loading={loading === 'signin'}
              variant="outline"
              onClick={() => {
                setLoading('signin')
                setTimeout(() => router.push('/auth/signin'), 200)
              }}
            >
              {hero.login}
            </Button>
            {/* <Button
              onClick={toggleLocale}
              className="w-full"
              color="neutral"
              size="sm"
            >
              {locale}
            </Button> */}
          </div>
        </div>
      )}
      <div className="flex flex-col max-w-[1280px] mx-auto lg:px-16">
        <div
          className="max-xl:grid-cols-1
         items-center content-center justify-around
         gap-32 py-36 max-sm:py-32 max-md:px-4 max-xl:px-[25px] max-lg:px-32 max-sm:px-8"
        >
          <div className="flex flex-col gap-4 h-full max-sm:h-screen">
            <h1 className="text-neutral-300 font-title font-bold max-md:text-3xl text-[64px]">
              {hero.title}
            </h1>

            <span className="font-primary text-neutral-500 text-xl mx-sm:text-blue-500 break-words max-w-[1000px]">
              {hero.hero}
            </span>
            {hero.action ? (
              <div className="flex justify-start min-w-32 items-start content-start">
                <Button
                  loading={loading === 'pricing'}
                  onClick={() => {
                    setLoading('pricing')
                    setTimeout(() => {
                      document
                        .getElementById('pricing')
                        ?.scrollIntoView({ behavior: 'smooth' })
                      setTimeout(() => setLoading(''), 250)
                    }, 250)
                  }}
                  // className="hover:shadow-primary hover:shadow-[0px_0px_16px_0px]"
                >
                  {hero.action}
                </Button>
              </div>
            ) : null}
          </div>
        </div>

        {!isMobile && (
          <>
            <div className="shadow-primary/30 shadow-[0px_0px_1200px_0px]"></div>
            <div className="flex justify-center">
              {/* <img
              className="h-[80vh] rounded-xl border border-sm border-neutral-700 shadow-primary/30 shadow-[0px_0px_1200px_0px]" //shadow-[3px_0px_49px_34px] shadow-white/10
              src="platform-wallet.png"
              alt="platform-image"
            /> */}

              <img
                className="[mask-image:url(/macbook-mask.png)] [mask] w-[65vw] [mask-size:contain]"
                src="platform-wallet-macbook.png"
                alt="platform-image"
              />
            </div>
          </>
        )}
        <Pricing pricing={pricing} />

        <div className="flex w-full justify-center py-32 border-t border-neutral-800">
          <FAQ />
        </div>
      </div>
      <div className="flex w-full justify-center border-t border-neutral-800">
        <Footer />
      </div>
    </div>
  )
}

export default LandingPageView
