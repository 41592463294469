import { Slot } from '@radix-ui/react-slot'
import { cva, VariantProps } from 'class-variance-authority'
import Image from 'next/image'
import * as React from 'react'

import { cn } from '@/lib/utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-landing font-semibold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-950 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:focus-visible:ring-neutral-300',
  {
    variants: {
      variant: {
        primary:
          'bg-button focus-visible:outline-primary hover:bg-buttonHover disabled:bg-buttonDisabled text-neutral-800',
        neutral:
          'bg-neutral-800 focus-visible:neutral-200 hover:bg-neutral-700 disabled:bg-neutral-600 text-neutral-200',
        light:
          'bg-neutral-300 hover:bg-neutral-200 disabled:bg-neutral-600 text-platform',
        violet:
          'bg-violet-600 focus-visible:outline-violet hover:bg-violet-500 disabled:bg-violet-400 text-neutral-200',
        outline:
          'border text-primary border-primary bg-transparent shadow-sm hover:bg-buttonHover hover:text-platform dark:border-neutral-800 dark:bg-neutral-950 dark:hover:bg-neutral-800 dark:hover:text-neutral-50',
        secondary:
          'bg-neutral-100 text-neutral-900 shadow-sm hover:bg-neutral-100/80 dark:bg-neutral-800 dark:text-neutral-50 dark:hover:bg-neutral-800/80',
        ghost:
          'hover:bg-neutral-100 hover:text-neutral-900 dark:hover:bg-neutral-800 dark:hover:text-neutral-50',
        link: 'text-neutral-900 underline-offset-4 hover:underline dark:text-neutral-50'
      },
      size: {
        xs: 'h-4 px-2 text-sm rounded-sm',
        sm: 'h-6 px-3 text-sm rounded-sm',
        md: 'h-8 px-4 text-md rounded-lg',
        lg: 'h-10 px-8 text-xl rounded-lg',
        icon: 'h-9 w-9'
      },
      full: {
        true: ['w-full'],
        false: ['max-w-[200px]']
      },
      shadow: {
        true: ['hover:shadow-primary hover:shadow-[0px_0px_16px_0px]'],
        false: null
      }
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md'
    }
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
  shadow?: boolean
  full?: boolean
}

const spinnerMap = new Map([
  ['primary', '/spinner-light.svg'],
  ['neutral', '/spinner-neutral.svg'],
  ['violet', '/spinner-violet.svg'],
  ['light', '/spinner-neutral.svg']
])

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading,
      shadow,
      full,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, full, shadow, className }),
          'rounded-lg font-landing font-semibold shadow-sm leading-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 flex flex-row justify-center items-center gap-2 animate-all transition-all duration-50 ease-in-out disabled:hover:animate-none cursor-pointer active:translate-y-[1px]'
        )}
        ref={ref}
        {...props}
      >
        <span className="relative flex gap-2 items-center">
          {loading && (
            <Image
              alt="Spinner"
              src={spinnerMap.get(variant as string) ?? '/spinner-light.svg'}
              height={16}
              width={16}
              className="animate-spin"
            />
          )}
          <span
            className={`font-primary text-[14px] ${
              size === 'sm' ? 'text-xs' : size === 'lg' ? 'text-lg' : 'text-sm'
            } group-hover:text-platform`}
          >
            {children}
          </span>
        </span>
      </Comp>
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
