'use client'

import { createContext, useContext, useMemo, useState } from 'react'

import { ReactComponentProps } from '@/presentation/types'

import EN from '../../main/locale/en.json'
import PT from '../../main/locale/pt.json'

type LocaleType = {
  hero: {
    logo: boolean
    title: string
    hero: string
    action: string
    login: string
  }
  pricing: {
    chooseYourPlan: string
    monthly: string
    annual: string
    billedAnnually: string
    billedMonthly: string
    saveAnnually: string
    perYear: string
    perMonth: string
    planAvailableSoon: string
    checkoutSessionFailed: string
  }
}

const LOCALE_RESOURCES: Record<'EN' | 'PT', LocaleType> = { EN, PT }

export type LocaleContextType = {
  locale: string
  localeContent: LocaleType
  toggleLocale: () => void
}

export const LocaleContext = createContext<LocaleContextType>(
  {} as LocaleContextType
)

export const LocaleProvider = ({ children }: ReactComponentProps) => {
  const [locale, setLocale] = useState<'EN' | 'PT'>('EN')

  const toggleLocale = () => {
    setLocale(prev => (prev === 'EN' ? 'PT' : 'EN'))
  }

  const localeContent = useMemo(() => LOCALE_RESOURCES[locale], [locale])

  return (
    <LocaleContext.Provider value={{ locale, localeContent, toggleLocale }}>
      {children}
    </LocaleContext.Provider>
  )
}

export const useLocaleContext = () => useContext(LocaleContext)
