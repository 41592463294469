import { Facebook, Twitter, Instagram, Linkedin, Github } from 'lucide-react'
import Link from 'next/link'

const Company = () => (
  <div>
    <h3 className="text-primary font-semibold text-md mb-4">Company</h3>
    <ul className="space-y-2">
      <li>
        <Link href="#about-us" className="hover:text-primary transition-colors">
          About Us
        </Link>
      </li>
      <li>
        <Link
          href="#contact-us"
          className="hover:text-primary transition-colors"
        >
          Contact Us
        </Link>
      </li>
      <li>
        <Link href="#careers" className="hover:text-primary transition-colors">
          Careers
        </Link>
      </li>
    </ul>
  </div>
)

const QuickLinks = () => (
  <div>
    <h3 className="text-primary font-semibold text-md mb-4">Quick Links</h3>
    <ul className="space-y-2">
      <li>
        <Link href="#pricing" className="hover:text-primary transition-colors">
          Pricing
        </Link>
      </li>
      <li>
        <Link href="#faq" className="hover:text-primary transition-colors">
          FAQ
        </Link>
      </li>
      <li>
        <Link
          href="/privacy-policy"
          className="hover:text-primary transition-colors"
        >
          Privacy Policy
        </Link>
      </li>
      <li>
        <Link
          href="/terms-of-service"
          className="hover:text-primary transition-colors"
        >
          Terms of Service
        </Link>
      </li>
    </ul>
  </div>
)

const ConnectWithUs = () => (
  <div>
    <h3 className="text-primary font-semibold text-md mb-4">Connect With Us</h3>
    <div className="flex space-x-4">
      <Link href="#" className="hover:text-primary transition-colors">
        <Facebook className="w-6 h-6" />
        <span className="sr-only">Facebook</span>
      </Link>
      <Link href="#" className="hover:text-primary transition-colors">
        <Twitter className="w-6 h-6" />
        <span className="sr-only">Twitter</span>
      </Link>
      <Link
        href="https://www.instagram.com/skall.app"
        className="hover:text-primary transition-colors"
      >
        <Instagram className="w-6 h-6" />
        <span className="sr-only">Instagram</span>
      </Link>
      <Link href="#" className="hover:text-primary transition-colors">
        <Linkedin className="w-6 h-6" />
        <span className="sr-only">LinkedIn</span>
      </Link>
      <Link href="#" className="hover:text-primary transition-colors">
        <Github className="w-6 h-6" />
        <span className="sr-only">GitHub</span>
      </Link>
    </div>
  </div>
)

const Newsletter = () => (
  <div>
    <h3 className="text-primary font-semibold text-md mb-4">Newsletter</h3>
    <p className="mb-4">Stay updated with our latest news and offers.</p>
    <form className="flex">
      <input
        type="email"
        placeholder="Enter your email"
        className="bg-platform text-neutral-300 text-sm px-4 py-2 rounded-l-md focus:outline-none flex-grow border border-neutral-800 focus:border-primary transition-colors"
        aria-label="Email for newsletter"
      />
      <button
        type="submit"
        className="bg-primary text-platform px-4 py-2 rounded-r-md hover:bg-secondary transition-colors"
      >
        Subscribe
      </button>
    </form>
  </div>
)

export const Footer = () => {
  return (
    <footer className="text-neutral-400 pt-12 pb-8 font-landing lg:px-24">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-8 sm:gap-16">
          <QuickLinks />
          <Company />
          <ConnectWithUs />
          <Newsletter />
        </div>
        <div className="mt-4 pt-4 text-center text-neutral-600">
          <p>&copy; {new Date().getFullYear()} Skall. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}
