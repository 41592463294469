'use client'

import { CheckIcon } from 'lucide-react'
import { useRouter } from 'next/navigation'
import { useState } from 'react'
import { toast } from 'sonner'

import { createCheckoutSession } from '@/app/actions/create-checkout-session'
import { Button } from '@/components/ui/button'
import { BillingProductModel } from '@/domain/models'
import { useLocaleContext } from '@/presentation/contexts/locale'
import { usePricing } from '@/presentation/hooks/use-pricing'

type Props = {
  pricing: BillingProductModel[]
}

export const Pricing = ({ pricing }: Props) => {
  const [isAnnual, setIsAnnual] = useState(false)
  const router = useRouter()
  const [loading, setLoading] = useState('')

  const { plans } = usePricing(pricing)

  const {
    localeContent: { pricing: locale }
  } = useLocaleContext()

  return (
    <div
      id="pricing"
      className="flex w-full items-center justify-center border-t border-neutral-800 py-[200px] px-4"
    >
      <div className="container mx-auto">
        <div className="text-center mb-24">
          <h1 className="text-neutral-300 font-title font-bold max-md:text-3xl text-[64px] mb-4">
            {locale.chooseYourPlan}
          </h1>
          <div className="flex items-center justify-center space-x-2">
            <span className="text-md font-landing font-semibold text-neutral-300">
              {locale.monthly}
            </span>
            <button
              onClick={() => setIsAnnual(!isAnnual)}
              className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-none focus:ring-primary ${
                isAnnual ? 'bg-primary' : 'bg-neutral-700'
              }`}
            >
              <span
                className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                  isAnnual ? 'translate-x-6' : 'translate-x-1'
                }`}
              />
            </button>
            <span className="text-md font-landing font-semibold text-neutral-300">
              {locale.annual}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 bg-transparent sm:px-24">
          {plans
            .filter(p =>
              isAnnual
                ? p.price?.interval === 'year'
                : p.price?.interval === 'month'
            )
            .map((plan, index) => (
              <div
                key={plan.name}
                className={`flex flex-col rounded-lg bg-platform shadow-lg overflow-hidden cursor-pointer transform hover:scale-[1.02] transition-transform duration-100 border border-neutral-800 text-neutral-200 ${plan.color}`}
              >
                <div className="p-10 pb-6">
                  <h3
                    className={`text-2xl leading-8 font-landing font-extrabold sm:text-3xl sm:leading-9 ${plan.color}`}
                  >
                    {plan.name}
                  </h3>
                  <p className="mt-4 text-sm font-landing leading-5 text-neutral-400">
                    {isAnnual ? locale.billedAnnually : locale.billedMonthly}
                  </p>
                  <div className="mt-4">
                    <span className="text-2xl font-landing font-extrabold text-white">
                      ${plan.price?.unitAmount.toFixed(2)}
                    </span>
                    <span className="text-base font-landing font-semibold text-neutral-400">
                      {isAnnual ? locale.perYear : locale.perMonth}
                    </span>
                  </div>
                  {isAnnual && (
                    <p className="mt-4 text-sm font-semibold text-green-400 font-landing">
                      {locale.saveAnnually} ${plan.saves} {locale.perYear}
                    </p>
                  )}
                </div>
                <div className="flex-1 p-10 pt-6">
                  <ul className="flex flex-col gap-2">
                    {plan.features.map(feature => (
                      <li
                        key={feature.description}
                        className="flex items-center"
                      >
                        <div className="flex-shrink-0">
                          <CheckIcon
                            className={`h-4 w-4 stroke-8 ${
                              feature.available
                                ? plan.color
                                : 'text-neutral-400'
                            }`}
                          />
                        </div>
                        <p
                          className={`ml-1 text-xs leading-6 font-landing ${
                            feature.available
                              ? 'text-neutral-300'
                              : 'text-neutral-500 line-through'
                          }`}
                        >
                          {feature.description}
                        </p>
                      </li>
                    ))}
                  </ul>
                  <div className="mt-8">
                    <div className="rounded-lg shadow-md"></div>
                    <Button
                      variant={plan.buttonColor}
                      full
                      loading={loading === plan.price.id}
                      onClick={async () => {
                        if (!plan.active) {
                          toast.info(locale.planAvailableSoon)
                          return
                        }

                        setLoading(plan.price.id)
                        const result = await createCheckoutSession({
                          userId: '1',
                          priceId: plan.price.id,
                          successUrl: `${process.env.NEXT_PUBLIC_APP_URL}/checkout?success=true`,
                          cancelUrl: `${process.env.NEXT_PUBLIC_APP_URL}#pricing`
                        })

                        if (result.url) {
                          router.push(result.url)
                        } else {
                          toast.error(locale.checkoutSessionFailed)
                        }
                      }}
                    >
                      {plan.buttonLabel}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
