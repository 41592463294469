import(/* webpackMode: "eager" */ "/home/runner/work/skall-app/skall-app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/skall-app/skall-app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/skall-app/skall-app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/skall-app/skall-app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/skall-app/skall-app/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["LocaleProvider"] */ "/home/runner/work/skall-app/skall-app/src/presentation/contexts/locale.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/skall-app/skall-app/src/presentation/styles/global.lp.css");
